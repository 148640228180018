.loadingContainTienda{
    padding: 50px 10%;

}

.loadingTienda{
    width: 100%;
    height: 350px;
    overflow: hidden;
    margin-right: 2rem;
    margin-bottom: 2rem;
    background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
    background-size: 400% 400%;
   
}
.loadingImgPerfil{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    z-index: 2;
    margin-top: -4rem;
    background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
    background-size: 400% 400%;
    border: 2px solid var(--blanco);
    margin-left: 3rem;
}
@media (max-width: 700px){
    .loadingContainTienda{
        padding: 20px 3%;
    
    }
    .loadingTienda{
        width: 100%;
        height: 160px;
       
       
    }
    .loadingImgPerfil{
        width: 80px;
        height: 80px;
        margin-top: -4rem;
        margin-left: 40%;
    }
}