.detailLoading{
    padding: 120px 10%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.textLoadingDetail{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}
.textLoadingDetail span{
     width: 23rem;
     height: 1rem;
     border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
     -webkit-animation: gradientMove 1s linear infinite;
}
.imgLoadingDetail{
    width: 34rem;
    height: 30rem;
    border-radius: 10px;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}
@media (max-width: 1024px){
    .detailLoading{
        padding: 0px 0;
       gap: 0;
    }
    .textLoadingDetail span{
        width: 100%;
        height: 1rem
        
    }
    .imgLoadingDetail{
        width: 100%;
        height: 50vh;
    }
    .textLoadingDetail{
        width: 100%;
        padding: 20px;
    }
}