.items{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.items fieldset{
    width: 15%;
}
#deNoneInput{
    display: none;
}
@media (max-width: 824px){
    .items fieldset{
        width: 45%!important;
    }
    .items{
        display: flex;
        gap: 10px;
        justify-content: space-between;
       
      
    }
}