.textTienda{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  
}
.textTienda img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
   
}
.iconCardTienda{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 3px;
    border-radius: 3px;
    font-size: 12px;
}
.textTienda h4{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 18px;
    color: var(--text-color2);
}
.textTienda h6{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color2);
}

#SwiperSlide-scroll-tiendas-products{
    position: relative;
    width: 20rem;
    height: 13rem;
    margin: 1rem 1rem  1rem  0rem ;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.256);
    margin-bottom: 2rem;
   
}
#SwiperSlide-scroll-tiendas-products img{
    width:  100%;
    height: 13rem;
    object-fit: cover;
   
}
#SwiperSlide-scroll-tiendas-products-Contain{
    padding: 1rem 0;
}

.cardTextProduct h4{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    font-weight: 500;
    font-size: 20px;
    color: var(--blanco);

}
.cardTextProduct .deFLexPrice h5{
    color: var(--blanco)!important;
    font-size: 16px;
}
.cardTextProduct{

    position: absolute;
    top:45%;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 6%, rgba(0, 0, 0, 0) 100%);
    color: var(--blanco);
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 2rem;
}
.cardTextProduct .iconCard{
    position: absolute;
    left: 87%;
    top:60%;
    bottom: 0;
    background-color: var(--color1);
    color: var(--blanco);
    padding: 3px;
    border-radius: 3px;
    font-size: 12px;
}

@media (max-width: 700px){
    .textTienda{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 0 2%;
      
    }

  
    .cardTextProduct h4{
        font-size: 18px;
        max-width: 20ch;
    }
    .textTienda h6{
        max-width: 20ch;
        font-size: 12px;
    }
    .textTienda h4{
        font-size: 16px;
    }
    .cardTextProduct .deFLexPrice h5{
        color: var(--blanco)!important;
    }
    .textTienda img{
        width: 40px;
        height: 40px;
       
    }
    #SwiperSlide-scroll-tiendas-products-Contain{
        padding: 10px 4%  0;
    }

    #SwiperSlide-scroll-tiendas-products{
        width: 16rem;
        height: 10rem;
       
    }
    #SwiperSlide-scroll-tiendas-products img{
        height: 12rem;
       
    }
    .cardTextProduct{
        padding-top: 0;
    }
    .cardTextProduct .iconCard{
        position: absolute;
        left: 87%;
        top:45%;
    }
}