.TiendaContain  .BannerContain{
    padding: 0%  0;
    padding-top: 5rem;
    margin-bottom: 3rem;
}
.TiendaContain{
    background-color: var(--gris);
}
@media (max-width: 700px){
    .TiendaContain .BannerContain{
        padding: 0% 3%;
        padding-top: 1rem;
        margin-bottom: 2rem;
    }
    .TiendaContain{
        border-radius: 0 30px 0 0px;
    }
}