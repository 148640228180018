.ProductsContain{
    width: 100%;
    background-color: var(--blanco);
    margin-top: 2rem;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.068);
    min-height: 100vh;
 }
 .Products{
     display: flex;
     flex-direction: column;
     gap: 2rem;
     width: 100%;
     padding-top: 2rem;
 }
 .categoriaProductos{
     display: flex;
     gap: 2rem;
     width: 100%;
 }
 .cardProdcut{
     display: flex;
     overflow: hidden;
     width: 23rem;
     height: 8rem;
     border-radius: 10px;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
     background-color: var(--blanco);
    
 }
 .cardProdcutSelected{
     display: flex;
     width: 23rem;
     height: 8rem;
     border-radius: 20px;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
     background-color: var(--blanco);
     padding: 10px;
     overflow: hidden;
     margin-bottom: 3rem;
    
 }
 .cardProdcutmasVendido{
     display: flex;
     flex-direction: column;
     overflow: hidden;
     width: 13rem;
     height: 15rem;
     border-radius: 10px;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
     background-color: var(--blanco);
     position: relative;
 }
 .masVendido{
     position: absolute;
     background-color: var(--color1);
     padding: 6px;
     color: var(--blanco);
     border-radius: 2px;
    
 }
 .iconCard{
     position: absolute;
     top: 78%;
     left: 90%;
     background-color: var(--color1);
     color: var(--blanco);
     padding: 3px;
     border-radius: 3px;
     font-size: 12px;
     
 }
 .cardProdcutmasVendido  img{
     width: 100%;
     height: 8rem;
     object-fit: cover;
    
     
 }
 .cardProdcut  img{
     width: 8rem;
     height: 8rem;
     object-fit: cover;
 }
 .cardProdcutSelected  img{
     width: 7rem;
     height: 7rem;
     object-fit: cover;
     border-radius: 15px;
 }
 .cardText{
     padding: 10px;
     display: flex;
     flex-direction: column;
     gap: 10px;
     justify-content: space-between;
 }
 .cardText h4{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 20ch;
     font-weight: 500;
     font-size: 19px;
     color: var(--text-color2);
 }
 .cardText h5{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 40ch;
     font-weight: 500;
     font-size: 15px;
     color: var(--color1);
 }
 .cardText span{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 27ch;
     font-weight: 400;
     font-size: 14px;
     color: var(--text-color2);
 }
 .fixed {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     z-index: 999;
     padding: 0px 10%;
     background-color: var(--blanco);
   }
   .categoriasInputs{
     display: flex;
     align-items: center;
     overflow-x: scroll;
     gap: 2rem;
     padding-bottom: 10px;
   }
   .categoriasInputs::-webkit-scrollbar{
  
     background-color: transparent;
     width: 5px;
     height: 5px;
 }
  
 .categoriasInputs::-webkit-scrollbar-thumb {
     background-color: var(--color1);
     width: 5px;
     height: 5px;
     border-radius: 10px;
 }
   .categoriasInputs input{
     border: none;
     cursor: pointer;
     z-index: 10;
     padding: 10px 20px 5px;
     font-size: 18px;
     color: var(--text-color2);
     border-radius: 20px;
    
     
 }
 
   #swiper_container_products{
   
     width: 100%;
   
   }
   #SwiperSlide-scroll-products{
     width: 23rem;
     margin: 1rem 1rem 2rem 0px;
   
   }
   #SwiperSlide-scroll-products-masvendidos{
     width: 13rem;
     margin: 0rem 1rem 2rem 10px;
   }
   .categoriSection{
     display: flex;
     flex-direction: column;
     width: 100%;
     gap: 1rem;
    
   
 }
 .espacio{
     height: 100vh;
 }
 
 
 .modalText{
     padding: 20px;
     display: flex;
     flex-direction: column;
     gap: 10px;
     background-color: var(--blanco);
     border-radius: 20px 20px;
     margin-top: -2rem;
     z-index: 2;
     overflow-y: scroll;
     height: 40vh;
     
 }
 .modalText::-webkit-scrollbar{
     width: 5px;
     height: 5px;
     cursor: pointer;
     background-color: transparent;
     
    
 }
  
 .modalText::-webkit-scrollbar-thumb {
     background-color: var(--color1);
     border-radius: 10px;
 }
 .modalText p,h2{
     color: var(--text-color2);
     white-space: pre-line;
 }
 .modalText h5{
     color: var(--color1);
     font-size: 20px;
 }
 .modal-content-detail{
     display: flex;
     flex-direction: column;
     position: relative;
    
 }
 .modal-detail{
     top: 0;
     background-color: var(--blanco);
     width: 30%;
     height: 90vh;
     display: flex;
     flex-direction: column;
     border-radius: 10px;
     border: none;
     overflow: hidden;
 }
 .overlay-detail {
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: rgba(0, 0, 0, 0.333); 
     z-index: 999; 
 }
 
 #SwiperSlide-scroll-img{
     display: flex;
     width: 100%;
 }
 #swiper_container_Imgs{
     width: 100%;
     height: 40vh;
 }
 #swiper_container_Imgs img{
     width: 100%;
     object-fit: cover;
 
 }
 .deFlexCart{
     display: flex;
     gap: 1rem;
     align-items: center;
     margin-left: 2rem;
     margin-top: 1rem;
 }
 .deFlexGoTocart{
     display: flex;
     gap: 1rem;
     align-items: center;
     width: 100%;
     bottom: 0;
     width: 100%;
     left: 0;
     right: 0;
     z-index: 2;
 }
 .deFlexCart button{
     color: var(--blanco);
     background-color: var(--color1);
     border: none;
     cursor: pointer;
     border-radius: 5px;
     padding: 4px 8px;
     
 }
 .btnAdd{
     padding: 12px 20px;
     color: var(--blanco);
     background-color: var(--color1);
     display: flex;
     justify-content: center;
     align-items: center;
     border: none;
     font-size: 17px;
     cursor: pointer;
     box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
     border-radius: 10rem;
     gap: 10px;
     width: 10rem;
 }
 .categoriSectionSelected{
     display: flex;
     flex-wrap: wrap;
     gap: 2rem;
     width: 100%;
 }
 .cardTextSelected{
     padding: 10px;
     display: flex;
     flex-direction: column;
     gap: 6px;
     justify-content: space-between;
 }
 .cardTextSelected h4{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 20ch;
     font-weight: 500;
     font-size: 19px;
     color: var(--text-color2);
 }
 .cardTextSelected h5{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 40ch;
     font-weight: 500;
     font-size: 15px;
     color: var(--color1);
 }
 .cardTextSelected span{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 25ch;
     font-weight: 400;
     font-size: 14px;
     color: var(--text-color2);
 }
 .itemsDetail {
     display: flex;
     flex-wrap: wrap;
     gap: 1rem;
 }
 .itemsDetail label{
     display: flex;
     gap: 2px;
     align-items: center;
     background-color: var(--gris);
     border-radius: 2px;
     padding: 10px;
    
 }
 .deFLexPrice{
     display: flex;
     gap: 20px;
     
 }
 .deFLexlogo{
     display: flex;
     gap: 10px;
     align-items: center;
 }
 .precioTachado {
     text-decoration: line-through;
     color: var(--text-color2)!important;
   
   }
 
 .deFlexTitlesection{
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 0 1% 1rem;
     border-bottom: 1px solid var(--gris2);
     padding-bottom: 1rem;
 }
 .deFlexTitlesection img{
    width: 60px ;
    height: 60px;
    border-radius: 100%;
    
}
 .deFlexTitlesection h3{
     font-weight: 500;
     color: var(--text-color2);
     font-size: 20px;
 }
 .deFlexTitlesection button{
     background-color: transparent;
     border: none;
     color: var(--color1);
 }
 
 .logoTiendaProducto{
     width: 25px!important;
     height: 25px!important;
     border-radius: 100%;
    
 }
 .deFLexlogo h5{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 40ch;
     font-weight: 500;
     font-size: 15px;
     color: var(--text-color2);
 }
 @media (max-width: 700px){
     .iconCard{
         top: 72%;
     }
     .overlay-detail {
         justify-content: flex-end;
         align-items:flex-end;
        
     }
     .modal-detail{
         width: 100%;
         height: 100%;
         border-radius: 0;
 }
     
     .deFlexTitlesection{
     
        margin: 0 4% ;
        padding-bottom: 1rem;
   
 }
 .deFlexTitlesection h3{
     font-size: 17px;
 }
     .fixed {
         padding: 0px 2%;
         background-color: var(--blanco)!important;
       }
      
       .categoriasInputs input{
         padding: 10px 20px 8px ;
         font-size: 14px;
         background-color: var(--gris2);
       }
       
       .categoriasInputs{
         background-color: transparent;
         padding: 0px 3%;
         padding-top: 0;
         padding-bottom: 0;
         gap: 10px;
       }
     #swiper_container_products{
         width: 100%;
         padding: 0px 3% ;
       }
     #SwiperSlide-scroll-products{
         width: 19rem;
         margin: 1rem 1rem 1rem 0rem;
       }
 
       #SwiperSlide-scroll-products-masvendidos{
         width: 10rem;
         margin: 0rem 1rem 1rem 3px;
       }
       #swiper_container_Imgs{
         height: 20rem;
     }
 
       .cardProdcutmasVendido{
        width: 10rem;
        height: 12rem;
     }
     .cardProdcutSelected  img{
         width: 5rem;
         height: 5rem;
     }
     .categoriSectionSelected{
         gap: 1rem;
         padding: 2%;
         flex-wrap:initial;
         flex-direction: column;
         width: 100%;
        
     }
     .cardProdcutSelected{
         width: 100%;
         height: 7.5rem;
         background-color: transparent;
         box-shadow: none;
         border-radius: 0;
         border-bottom: 1px solid var(--gris2);
         align-items: center;
         margin-bottom: 0;
 }
     .cardProdcutmasVendido img{
         height: 6rem;
         height: 6rem;
     }
     .ProductsContain{
        padding: 20px 0%;
        margin-top: 1rem;
        min-height: 100%;
     }
     .cardProdcut{
         flex-direction: row;
         width: 19rem;
         height: 6rem;
     }
 
     .cardProdcut img{
         width: 6rem;
         height: 6rem;
         object-fit: cover;
         
     }
     .cardText h4{
         max-width: 15ch;
         font-size: 16px;
     }
     .cardText h5{
       
         max-width: 25ch;
         
     }
     .cardText span{
       
         max-width: 20ch;
     }
     
     .Products{
         display: flex;
         flex-wrap: wrap;
         gap: 1rem;
        padding: 0;
        padding-top: 10px;
     }
     .categoriasInputs::-webkit-scrollbar{
  
         background-color: transparent;
      
     }
      
     .categoriasInputs::-webkit-scrollbar-thumb {
         background-color: transparent;
     }
 
     .deFLexlogo h5{
         max-width: 30ch;
         font-size: 13px;
     }
     .deFLexlogo{
         gap: 5px;
     }
     .logoTiendaProducto{
         width: 20px!important;
         height: 20px!important;
        
     }
     .cardTextSelected h4{
        font-size: 16px;
    }
 }