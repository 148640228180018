.AuthContainerClient{
    display: flex;
    gap: 10px;
}

.modalAuth-content {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
}
.modalAuth-content fieldset{
    width: 100%;
    
}
.modalAuth-content .infoUser{
    box-shadow: none;
    height: 100%!important;
}
.deFlexModal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--text-color2);
}
.deFlexModal h3{
    font-weight: 500;
    color: var(--text-color2);
}
.modalAuth-content fieldset input{
    width: 100%;
    padding: 5px 15px;
}
.modalAuth-content .btn{
    width: 100%;
}
.modalAuth-content .btnLoading{
    width: 100%;
}
.modalAuth-content img{
    width: 70px;
    border-radius: 100%;
}
.toggleText {
    color: var(--color1);
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.modalAuth {
    top: 0;
    background-color: var(--blanco);
    width: 30%;
  
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: none;
}

.closeModal{
    display: flex;
    text-align: left;
    cursor: pointer;
}

.overlayAuth {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
    display: flex;
    justify-content: right;
}
.iconUser{
    color: var(--blanco);
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 20px;
    border-radius: 10px ;
    background-color: var(--color1);
    font-weight: 500;
}

.authButtonRegister{
    padding: 7px 25px;
    border-radius: 10rem;
    border: 1px solid var(--color1);
    cursor: pointer;
    background: linear-gradient(90deg, var(--color1) 27%, var(--color2)120%);
    color: var(--blanco);
}
.authButtonLogin{
    padding: 7px 25px;
    border-radius: 10rem;
    border: 1px solid var(--color1);
    cursor: pointer;
    color: var(--textcolor2);
    background-color: transparent;
}

.authButton{
    background-color: var(--blanco);
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px!important;
    color: var(--text-color2)!important;
    width: 9rem;
    text-align: center;
    justify-content: center;
}

.authButtons{
    display: flex;
    border: 10px;
}
.authButtons button{
    background-color: transparent;
    border: none;
    padding: 5px 15px;
}


@media (max-width: 1024px){
    .modalAuth {
       
        width: 100%;
        min-height: 100vh;
        max-height: 100%;
    }
    
    .iconUser{
        font-size: 18px;
        padding: 7px ;
        background-color: transparent;
    }
    .iconUser span{
        display: none;
    }
}