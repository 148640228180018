.bannerTiendaUser{
    width: 100%;
    height: 20vh;
}
.bannerTiendaUser img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logoTiendaUser{
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px solid var(--blanco);
  margin-top: -3rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.127);
}
.TiendaUserCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.TiendaUserCont h3{
    color: var(--text-color2);
    font-weight: 600;
}
.TiendaUser{
    background-color: var(--blanco);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
    width: 40%;
    min-height: 35vh;
    max-height: 35vh;
    border-radius: 10px;
    overflow: hidden;
}

.bannerTiendaUserLoading{
    width: 100%;
    height: 20vh;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}
.logoTiendaUserLoading{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 2px solid var(--blanco);
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
     margin-top: -3rem;
}

.textTiendaLoading{
    width: 160px;
    height: 15px;
    border-radius: 6px;
    background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
    background-size: 400% 400%;
    animation: gradientMove 1s linear infinite;
}

@media (max-width: 700px){
    .TiendaUser{
        width: 98%;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }
    .bannerTiendaUser{
        width: 100%;
        height: 16vh;
    }
    .logoTiendaUser{
      width: 70px;
      height: 70px;
      margin-top: -2rem;
    }
    .TiendaUserCont h3{
       margin-bottom: 1rem;
    }
}