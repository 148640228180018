.BannersTiendas{
    padding: 2rem 10% 0;
   
}

#SwiperSlide-scroll-banner-tiendas{
    width: 25rem;
    height: 14rem;
    overflow: hidden;
    margin-right: 2rem;
    border-radius: 20px;
}
#SwiperSlide-scroll-banner-tiendas img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#SwiperSlide-scroll-banner-tiendasLoading{
    width: 25rem;
    height: 14rem;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
     margin-right: 2rem;
}
@media (max-width: 700px){
    .BannersTiendas{
        padding: 1rem 0% 0;
       
    }
    #SwiperSlide-scroll-banner-tiendas{
        width: 16rem;
        height: 8rem;
        margin-right: 1rem;
    }
    #SwiperSlide-scroll-banner-tiendasLoading{
        width: 16rem;
        height: 8rem;
        margin-right: 1rem;
    }
}