.previevImagesLogoBanner{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin-top: 1rem;
}
.previevImagesBanner{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.previevImagesLogo{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
    position: relative;
    margin-top: -2rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
   
}