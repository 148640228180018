.loadingContain{
    padding: 20px 0%;
   
}
.cardsLoading{
    display: flex;
    overflow: hidden;
    gap: 2rem;
}
.cardLoading{
    width: 13rem;
    height: 14rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}
.cardsLoading2{
    display: flex;
    gap: 2rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: 2rem;
}
.cardLoading2{
    width: 23rem;
    height: 8rem;
    margin-right: 2rem;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}


  @media (max-width: 700px){
    .cardLoading{
        width: 10rem;
        height: 12rem;
        margin-right: 1rem;
    }
    .cardsLoading{
        padding-left: 3%;
    }
    .cardsLoading2{
        padding-left: 3%;
    }
    .cardLoading2{
        width: 19rem;
        height: 6rem;
        margin-right: 1rem;
    }
    
  }