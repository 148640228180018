.profileContain{
    background-color: var(--blanco);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 9900000000!important;
    
}

.profileContain img{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.127);
    background-color: var(--blanco);
}
.profileContain .fondo img{
    width: 100%;
    height: 5rem;
    object-fit: cover;
    border-radius: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.127);
    background-color: var(--blanco);
    height: 15rem;
}
.profileContain .fondo{
    display: flex;
    flex-direction: column;
    height: 15rem;

}

.profileContain h2{
    font-weight: 500;
    color: var(--text-color2);
}
.socials{
    display: flex;
    gap: 2rem;
}
.socials a{
    color: var(--text-color2);
    font-size: 18px;
}
.profileText{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px 5%;
    margin-top: -5rem;
}

.profileText a{
    color: var(--text-color2);
    border-bottom: 1px solid var(--gris2);
    padding-bottom: 10px;
}
@media (max-width: 700px) {
    
    .profileContain img{
        width: 4rem;
        height: 4rem;
    }
    .profileContain .fondo img{
        width: 100%;
        height: 11rem;
    }
    .profileText{
        margin-top: -8rem;
    }
    
}