.TiendaContain{
    padding: 0px 10%;
    width: 100%;
   
}
#TiendaContain{
    padding-top: 3rem;
}
#SwiperSlide-scroll-tiendas{
    width: 20rem;
    
    margin: 1rem 1rem 2rem 0rem;
}

.cardTienda{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
    background-color: var(--blanco);
}


.cardTextTienda{
    
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cardTextTienda img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}
.imgTiendaBanner{
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.cardTextTienda h4{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    font-weight: 600;
    font-size: 18px;
    color: var(--text-color2);
}
.cardTextTienda h5{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    font-weight: 400;
    font-size: 13px;
    color: var(--text-color2);
}

.cardTextTienda h6{
    background-color: var(--color1);
    padding: 6px;
    color: var(--blanco);
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
}

.categoriSectionSelectedTienda{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}
@media (max-width: 700px){

    .categoriSectionSelectedTienda{
        gap: 0;
        padding:  0 3%;
        flex-wrap:initial;
        flex-direction: column;
        width: 100%;
       
       
    }
    .categoriSectionSelectedTienda .cardTienda{
           width: 100%;
    }
    .categoriSectionSelectedTienda #SwiperSlide-scroll-tiendas{
        width: 100%;
 }
    .TiendaContain{
        padding: 00px 0%;
        width: 100%;
      
    }
    #SwiperSlide-scroll-tiendas{
        width: 16rem;
        
    }
    .imgTiendaBanner{
        width: 100%;
        height: 110px;
        object-fit: cover;
    }
   
    #swiper_container_tiendas{
        padding: 0 4%;
    }
    #TiendaContain{
        padding-top: 1rem;
    }
    .cardTextTienda h4{
       
        max-width: 16ch;
        font-size: 16px;
    }
    .cardTextTienda h5{
        max-width: 16ch;
    }
}