.cardLoadingTienda{
    width: 20rem;
    height: 14rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}
@media (max-width: 700px){
    .cardLoadingTienda{
        width: 16rem;
        height: 12rem;
    }
}